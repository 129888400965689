import React from 'react';
import { withRouter } from 'react-router-dom';
import { H2, Intent, Spinner, Tag, HTMLSelect, Card, H3, NonIdealState, Classes, Tabs, Tab, Icon, Switch, Alignment, Divider } from '@blueprintjs/core';

import { formatRelative } from 'date-fns';
import { IconNames } from '@blueprintjs/icons';

export default withRouter(class Dashboard extends React.Component {

    constructor() {
        super();

        this.state = {
            error: null,
            isLoaded: false,
            isReloading: false,
            organizations: [],
            stats: []
        };
    }

    setStateAsync = (state) => new Promise((resolve) => {
        this.setState(state, resolve);
    });

    componentDidMount() {
        this.refresh();
    }

    fetchOrganizations = async (searchParams = this.props.location.search) => {
        try {
            const res = await fetch(`/api/organizations${searchParams}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (res.ok) {
                return {
                    organizations: await res.json()
                };
            } else if (res.status === 401) {
                this.props.history.push({ pathname: '/login', state: { referrer: this.props.location } });
                return null;
            } else {
                throw Object.assign(Error(res.statusText), {
                    title: res.status,
                    description: res.statusText
                });
            }
        } catch (err) {
            if (err instanceof TypeError) {
                throw Object.assign(err, {
                    title: "Network error",
                    description: err.message
                });
            } else {
                throw err;
            }
        }
    }

    fetchStats = async () => {
        try {
            const res = await fetch(`/api/stats`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (res.ok) {
                return {
                    stats: await res.json()
                };
            } else {
                throw Object.assign(Error(res.statusText), {
                    title: res.status,
                    description: res.statusText
                });
            }
        } catch (err) {
            if (err instanceof TypeError) {
                throw Object.assign(err, {
                    title: "Network error",
                    description: err.message
                });
            } else {
                throw err;
            }
        }
    }

    refresh = async (searchParams = this.props.location.search) => {
        try {
            const r = await Promise.all([
                this.fetchOrganizations(searchParams),
                this.fetchStats()
            ]);

            const results = r.reduce((acc, res) => ({ ...acc, ...res }), {});
            console.log(results);

            await this.setStateAsync({
                isLoaded: true,
                ...results
            });
        } catch (error) {
            await this.setStateAsync({
                isLoaded: true,
                error
            });
        }
    }

    reload = async (searchParams = new URLSearchParams(this.props.location.search)) => {
        const paramString = `?${searchParams.toString()}`;

        await this.setStateAsync({ isReloading: true });

        await this.refresh(paramString);
        this.props.history.push('/' + paramString);

        await this.setStateAsync({ isReloading: false });
    }

    getRankTimestamp = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        if (searchParams.has('timestamp')) {
            return parseInt(searchParams.get('timestamp'));
        } else {
            return this.state.stats[0].timestamp;
        }
    }

    setRankTimestamp = async (event) => {
        const rankTimestamp = parseInt(event.target.value);

        const searchParams = new URLSearchParams(this.props.location.search);
        if (this.state.stats[0].timestamp !== rankTimestamp) {
            searchParams.set('timestamp', rankTimestamp);
        } else {
            searchParams.delete('timestamp');
        }

        await this.reload(searchParams);
    }

    isExcludeWithRatingChecked = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        return searchParams.has('exclude_with_rating') && searchParams.get('exclude_with_rating') === 'true';
    }

    toggleExcludeWithRating = async () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        if (this.isExcludeWithRatingChecked()) {
            searchParams.delete('exclude_with_rating');
        } else {
            searchParams.set('exclude_with_rating', 'true');
        }

        await this.reload(searchParams);
    }

    selectedTab = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        if (searchParams.has('hot') && searchParams.get('hot') === 'true') {
            return 'hot';
        } else {
            return 'top';
        }
    }

    handleTabChange = async (tabName) => {
        const searchParams = new URLSearchParams(this.props.location.search);
        if (tabName === 'hot') {
            searchParams.set('hot', 'true');
        } else {
            searchParams.delete('hot');
        }

        await this.reload(searchParams);
    }

    render() {
        return (
            <div className='container'>
                {!this.state.isLoaded &&
                    <div className='banner'>
                        <Spinner size={64} />
                    </div>
                }
                {this.state.isLoaded && this.state.error === null && this.state.stats.length !== 0 &&
                    <>
                        <div className="group" style={{ marginTop: '2rem', marginBottom: '1.5em' }}>
                            <H2 style={{ paddingBottom: 0 }}>Dashboard</H2>

                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <HTMLSelect iconProps={{ icon: IconNames.HISTORY }} value={this.getRankTimestamp()} onChange={this.setRankTimestamp}>
                                    {
                                        this.state.stats.map(({ timestamp }, index) => {
                                            let now = new Date();
                                            return (
                                                <option key={index} value={timestamp}>
                                                    {(((index === 0) ? `Latest – ` : `Previous – `) + `${formatRelative(new Date(timestamp), now)}`)}
                                                </option>
                                            );
                                        })
                                    }
                                </HTMLSelect>
                            </div>
                        </div>

                        <Card>
                            <Tabs selectedTabId={this.selectedTab()} onChange={this.handleTabChange}>
                                <Tab id="top" title={<H3 style={{ display: 'flex', alignItems: 'baseline' }}>Top&nbsp;<Icon icon={IconNames.CHART} /></H3>} />
                                <Tab id="hot" title={<H3 style={{ display: 'flex', alignItems: 'baseline' }}>Hot&nbsp;<Icon icon={IconNames.FLAME} /></H3>} />
                                <Tabs.Expander />
                                <Switch label="Hide rated organizations" alignIndicator={Alignment.RIGHT} large={false} checked={this.isExcludeWithRatingChecked()} onChange={this.toggleExcludeWithRating} />
                            </Tabs>

                            <Divider style={{ width: '100%', marginTop: '0.5rem', marginLeft: '0' }} />

                            <>
                                {/* <HTMLTable interactive={this.state.isReloading ? false : true} className='flex-table' style={{ marginTop: '0.5rem' }} > */}
                                <ol className='grid-table'>
                                    <div className='header'>Rank</div>
                                    <div className='header'>Name</div>
                                    <div className='header'>Description</div>
                                    {/* <div className='header'>Headcount</div> */}
                                    {
                                        this.state.organizations.map((organization, index) => {

                                            let delta = 0;
                                            let firstRanking = false;

                                            organization.rank.sort((a, b) => b.timestamp - a.timestamp);

                                            if (organization.rank.length === 2) {
                                                delta = organization.rank[1].index - organization.rank[0].index;
                                            } else {
                                                firstRanking = true;
                                            }

                                            // const signalsText = Object.entries(organization.rank[0].input).map(([key, values]) => {
                                            //     const labels = values.map((v, i) => (v !== 0) ? this.state.stats.find(({ timestamp }) => organization.rank[0].timestamp === timestamp).labels[key][i] : null).filter(v => v);
                                            //     if (labels.length !== 0) {
                                            //         return labels.join(', ');
                                            //     } else {
                                            //         return null;
                                            //     }
                                            // }).filter(v => v).join(', ');

                                            if (organization.rank[0]) {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className='line' style={{ gridRowStart: (index + 2), gridColumnStart: 1, gridColumnEnd: 'span 4' }} key={organization.orgnr} onClick={() => this.props.history.push(`/organization/${organization.orgnr}`)}></div>

                                                        <div className='item' style={{ gridRowStart: (index + 2), gridColumnStart: 1 }}>
                                                            <div className={this.state.isReloading ? Classes.SKELETON : null} style={{ display: 'inline-flex' }}>
                                                                <span style={{ marginRight: '1em' }}>{organization.rank[0].index + 1}</span>
                                                                {delta !== 0 &&
                                                                    <Tag
                                                                        icon={(delta > 0) ? IconNames.ARROW_UP : IconNames.ARROW_DOWN}
                                                                        round={false}
                                                                        minimal={true}
                                                                        intent={(delta > 0) ? Intent.SUCCESS : Intent.DANGER}
                                                                    >
                                                                        {Math.abs(delta)}
                                                                    </Tag>
                                                                }
                                                                {firstRanking &&
                                                                    <Tag
                                                                        icon={IconNames.NOTIFICATIONS}
                                                                        round={false}
                                                                        minimal={true}
                                                                        intent={Intent.PRIMARY}
                                                                    >
                                                                        New
                                                                                </Tag>
                                                                }
                                                                {/* {delta === 0 &&
                                                                        <Tag
                                                                            icon='arrow-left'
                                                                            round={false}
                                                                            minimal={true}
                                                                            intent={Intent.PRIMARY}
                                                                        >
                                                                            {Math.abs(delta)}
                                                                        </Tag>
                                                                    } */}
                                                            </div>
                                                        </div>
                                                        <div className='item' style={{ gridRowStart: (index + 2), gridColumnStart: 2 }}>
                                                            <div className={this.state.isReloading ? Classes.SKELETON : null} style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}>
                                                                <span>{organization.name}</span>
                                                                {organization.rating !== null &&
                                                                    <Tag
                                                                        icon={IconNames.STAR}
                                                                        round={false}
                                                                        minimal={true}
                                                                        style={{ marginLeft: '1rem' }}
                                                                    >
                                                                        Rated
                                                                    </Tag>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div
                                                            className='item'
                                                            style={{
                                                                gridRowStart: (index + 2),
                                                                gridColumnStart: 3,
                                                                textOverflow: this.state.isReloading ? null : 'ellipsis',
                                                                // whiteSpace: 'nowrap',
                                                                // overflow: 'hidden'
                                                            }}
                                                        >
                                                            <span
                                                                className={this.state.isReloading ? Classes.SKELETON : null}
                                                            >
                                                                {
                                                                    organization.homepageSummary || (organization.nace.code + ' – ' + organization.nace.description + ' (NACE)')
                                                                }
                                                            </span>
                                                        </div>
                                                        {/* <div className='item' style={{ textAlign: 'right', gridRowStart: (index + 2), gridColumnStart: 4 }}>
                                                            <span className={this.state.isReloading ? Classes.SKELETON : null}>
                                                                {organization.numberOfEmployees}
                                                            </span>
                                                        </div> */}
                                                    </React.Fragment>
                                                );
                                            }

                                            return null;
                                        })
                                    }
                                </ol>
                            </>
                        </Card>
                    </>
                }
                {this.state.isLoaded && this.state.error === null && this.state.stats.length === 0 &&
                    <div className="banner">
                        <NonIdealState
                            icon={IconNames.HELP}
                            title="Empty"
                            description="Ops! Nothing here yet."
                        />
                    </div>
                }
                {this.state.isLoaded && this.state.error !== null &&
                    <div className="banner">
                        <NonIdealState
                            icon={IconNames.ERROR}
                            title={this.state.error.title}
                            description={this.state.error.description}
                        />
                    </div>
                }
            </div>
        );
    }
});