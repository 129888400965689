import React from "react";
import normalizeUrl from 'normalize-url';
import { format } from 'date-fns';

import { AppToaster } from './AppToaster.js';
import { IconNames } from "@blueprintjs/icons";
import {
    H2,
    H3,
    H5,
    UL,
    Tag,
    Card,
    Icon,
    Menu,
    Button,
    Intent,
    Callout,
    Spinner,
    Classes,
    Popover,
    Tooltip,
    Position,
    HTMLTable,
    ButtonGroup,
    NonIdealState,
    PopoverInteractionKind
} from "@blueprintjs/core";
import { withRouter } from 'react-router-dom';

import { Radar } from '@nivo/radar'
import Dark from "./radar-themes/Dark";

export default withRouter(class Organization extends React.Component {

    constructor() {
        super();
        this.state = {
            error: null,
            isLoaded: false,
            organization: null,
            stats: null,
            isFresh: true
        };
    }

    componentDidMount() {
        this.fetchOrganization();
    }

    fetchOrganization = async () => {
        try {

            let responses = await Promise.all([
                fetch(`/api/organizations/${this.props.match.params.orgnr}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
                fetch('/api/stats', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            ]);

            let keys = ['organization', 'stats'];

            let properties = {};
            for (const [i, res] of responses.entries()) {
                if (res.ok) {
                    properties[keys[i]] = await res.json();
                } else if (res.status === 401) {
                    this.props.history.push({ pathname: '/login', state: { referrer: this.props.location }});
                    return null;
                } else {
                    this.setState({
                        isLoaded: true,
                        error: {
                            title: res.status,
                            description: res.statusText
                        }
                    });
                    break;
                }
            }

            this.setState({
                isLoaded: true,
                ...properties
            });
        } catch (err) {
            this.setState({
                isLoaded: true,
                error: {
                    title: "Network error",
                    description: err.message
                }
            });
        }
    }

    handleDeleteRatingButtonClick = async () => {
        try {
            let res = await fetch(`/api/organizations/${this.props.match.params.orgnr}/rating`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (res.ok) {
                AppToaster.show({ icon: 'tick-circle', message: 'Feedback deleted!', intent: Intent.SUCCESS, timeout: 2500 });
            } else {
                AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to delete feedback.` });
            }

            await this.fetchOrganization();

        } catch (err) {
            console.err(err);
            AppToaster.show({ icon: 'error', message: `Network error. Unable to delete rating.` });
        }
    }

    handleRatingButtonClick = async score => {
        try {
            let res = await fetch(`/api/organizations/${this.props.match.params.orgnr}/rating`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ score })
            });

            if (res.ok) {
                // AppToaster.show({ icon: 'tick-circle', message: 'Rating updated!', intent: Intent.SUCCESS, timeout: 2500 });
            } else {
                AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to save rating.` });
            }

            await this.fetchOrganization();

        } catch (err) {
            console.err(err);
            AppToaster.show({ icon: 'error', message: `Network error. Unable to save rating.` });
        }

        this.setState({ isFresh: false });
    }

    handleIgnoreButtonClick = async ignore => {
        try {
            let res = await fetch(`/api/organizations/${this.props.match.params.orgnr}/ignore`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(ignore)
            });

            if (!res.ok) {
                AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to save rating.` });
            }

            await this.fetchOrganization();

        } catch (err) {
            console.err(err);
            AppToaster.show({ icon: 'error', message: `Network error. Unable to save rating.` });
        }

        this.setState({ isFresh: false });
    }

    render() {
        const spinner = () => (
            <div className="banner">
                <Spinner size={64} />
            </div>
        );

        const body = () => {

            const organization = this.state.organization;

            const stats = this.state.stats;

            let delta = 0;
            let increase = false;

            const data = [];
            if (organization.rank) {
                organization.rank.sort((a, b) => b.timestamp - a.timestamp); // Sort by most recent. TODO: is this necessary?

                if (organization.rank.length > 1) {
                    delta = organization.rank[1].index - organization.rank[0].index;
                    increase = delta > 0;
                }

                const ranks = organization.rank.map(rank => [rank, stats.find(({ timestamp }) => rank.timestamp === timestamp)]).slice(0, 3);

                for (const [i, [rank, stats]] of ranks.entries()) {
                    const radarKey = `${(i + 1)}. ${format(rank.timestamp, 'PPP')}`;
                    data.push([radarKey, Array.from(Object.entries(rank.input)).flatMap(([key, values]) => values.map((value, i) => {
                        return {
                            key: `${stats.labels[key][i]}`,
                            value: (value * 0.75) + 0.25
                        };
                    }))]);
                }
            }

            let radarData = new Map();
            let radarKeys = [];

            data.forEach(([radarKey, values]) => {
                radarKeys.push(radarKey);
                for (const { key, value } of values) {
                    if (radarData.has(key)) {
                        radarData.get(key)[radarKey] = value;
                    } else {
                        radarData.set(key, { key, [radarKey]: value });
                    }
                }
            });

            radarData = Array.from(radarData.values());
            radarKeys = radarKeys.reverse();

            return (
                <>
                    <div className="group" style={{ marginTop: '2rem', marginBottom: '1.5rem' }}>
                        <div>
                            {!organization.ignore && organization.rank && organization.rank[0] &&
                                <div style={{ display: 'flex' }}>
                                    <span className={Classes.TEXT_LARGE}>
                                        Rank {organization.rank[0].index + 1}
                                    </span>
                                    {delta !== 0 &&
                                        <Tag
                                            icon={increase ? 'arrow-up' : 'arrow-down'}
                                            round={false}
                                            minimal={true}
                                            intent={increase ? Intent.SUCCESS : Intent.DANGER}
                                            style={{ marginLeft: '0.5rem' }}
                                        >
                                            {Math.abs(delta)}
                                        </Tag>
                                    }
                                </div>
                            }
                            <H2>{organization.name}</H2>
                            {(() => {
                                if (organization.homepageSummary) {
                                    return (
                                        <p className={Classes.TEXT_MUTED} style={{ maxWidth: '72ch', textAlign: 'justify', marginBottom: 0 }}>
                                            {organization.homepageSummary}
                                            &nbsp;
                                            <Tooltip content='This summary was generated with machine learning.' position={Position.BOTTOM} interactionKind={PopoverInteractionKind.HOVER}>
                                                <Icon icon={IconNames.HELP} />
                                            </Tooltip>
                                        </p>
                                    );
                                } else {
                                    return (
                                        <Tooltip content={<span>NACE-code: {organization.nace.code}</span>} position={Position.LEFT} interactionKind={PopoverInteractionKind.HOVER}>
                                            <p className={Classes.TEXT_MUTED} style={{ marginBottom: 0 }}>{organization.nace.description}</p>
                                        </Tooltip>
                                    );
                                }
                            })()}
                        </div>
                        <div className='align-right' style={{ justifyContent: 'flex-end' }}>
                            <div className={Classes.TEXT_MUTED} style={{ marginBottom: '0.5rem' }}>
                                {this.state.isFresh && organization.rating && organization.rating.score >= 0.5 &&
                                    <>Is this organization still interesting?</>
                                }
                                {this.state.isFresh && organization.rating && organization.rating.score < 0.5 &&
                                    <>Is this organization still not interesting?</>
                                }
                                {this.state.isFresh && !organization.rating &&
                                    <>Is this organization interesting?</>
                                }
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ButtonGroup style={{ alignSelf: 'center' }}>
                                    <Button
                                        rightIcon={IconNames.THUMBS_UP}
                                        minimal={false}
                                        onClick={() => this.handleRatingButtonClick(1.0)}
                                        intent={(!this.state.isFresh && organization.rating && organization.rating.score === 1.0) ? Intent.PRIMARY : Intent.NONE}
                                        style={(this.state.isFresh && organization.rating && organization.rating.score === 1.0) ? { fontWeight: 'bold' } : null}
                                    >
                                        Very interesting
                                    </Button>
                                    <Button
                                        rightIcon={IconNames.THUMBS_DOWN}
                                        minimal={false}
                                        onClick={() => this.handleRatingButtonClick(0.0)}
                                        intent={(!this.state.isFresh && organization.rating && organization.rating.score === 0.0) ? Intent.PRIMARY : Intent.NONE}
                                        style={(this.state.isFresh && organization.rating && organization.rating.score === 0.0) ? { fontWeight: 'bold' } : null}
                                    >
                                        Not interesting
                                    </Button>
                                </ButtonGroup>
                                <div style={{ alignSelf: 'center', marginLeft: '0.5rem' }}>
                                    <Popover
                                        position={Position.BOTTOM_RIGHT}
                                    >
                                        <Button icon="more" minimal={false} outlined={true} />
                                        <Menu>
                                            <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} targetProps={{ style: { width: '100%'}}} disabled={!organization.rating}>
                                                <Menu.Item icon="cross" text="Delete feedback" shouldDismissPopover={false} disabled={!organization.rating}/>
                                                <div key="text">
                                                    <H5>Confirm deletion</H5>
                                                    <p>Are you sure you want to delete your feedback? This action is irreversible.</p>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                                                        <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                                                            Cancel
                                                        </Button>
                                                        <Button intent={Intent.DANGER} className={Classes.POPOVER_DISMISS} onClick={() => this.handleDeleteRatingButtonClick()}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Popover>

                                            {organization.ignore &&
                                                <Menu.Item icon="play" text="Include in ranking" onClick={() => this.handleIgnoreButtonClick(false)}/>
                                            }
                                            {!organization.ignore &&
                                                <Menu.Item icon="pause" text="Exclude from ranking" onClick={() => this.handleIgnoreButtonClick(true)}/>
                                            }
                                        </Menu>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>

                    { organization.ignore &&
                        <Callout title="Excluded from ranking" style={{ marginBottom: '1.5rem' }}>
                            <p style={{ textAlign: 'justify' }}>
                                This organization is excluded from the ranking process. It will not show up in the recommendations list. You can include it in the ranking process at any time.
                            </p>
                        </Callout>
                    }

                    <Card style={{ marginBottom: '1.5rem' }}>
                        <H3>About</H3>
                        <div className="group">
                            <div style={{ maxWidth: '72ch' }}>
                                <UL className={Classes.TEXT_MUTED} style={{ listStyle: 'none', paddingLeft: 0 }}>
                                    <li><Icon icon="id-number" />&emsp;Org nr – {organization.orgnr}</li>
                                    <li><Icon icon="people" />&emsp;Number of employees – {organization.numberOfEmployees}</li>
                                    <li><Icon icon="calendar" />&emsp;Incorporation date – {format(new Date(organization.incorporationDate), 'PPP')}</li>
                                </UL>
                            </div>
                            <div className="align-right">
                                {(() => {
                                    if (organization.signals && organization.signals.input && organization.signals.input.homepage) {
                                        return (
                                            <a href={normalizeUrl(organization.signals.input.homepage.src)} target='_blank' rel="noopener noreferrer">{normalizeUrl(organization.signals.input.homepage.src)} <Icon icon="globe-network" /></a>
                                        );
                                    } else if (organization.homepage) {
                                        return (
                                            <a href={normalizeUrl(organization.homepage)} target='_blank' rel="noopener noreferrer">{normalizeUrl(organization.homepage)} <Icon icon="globe-network" /></a>
                                        );
                                    } else {
                                        return null;
                                    }
                                })()}
                                <a href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${organization.orgnr}`} target='_blank' rel="noopener noreferrer">Brreg <Icon icon="database" /></a>
                                <a href={`https://www.purehelp.no/m/company/account/${organization.orgnr}`} target='_blank' rel="noopener noreferrer">Purehelp <Icon icon="stacked-chart" /></a>
                            </div>
                        </div>
                    </Card>

                    <div className="grid">
                        {/* style={{ marginTop: '1.5rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}> */}
                        {organization.rank && organization.rank[0] &&
                            <>
                                <Card style={{ flex: '1 1 auto' }}>
                                    <H3>Signal radar</H3>

                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Radar
                                            width={500}
                                            height={380}
                                            data={radarData}
                                            keys={radarKeys}
                                            indexBy="key"
                                            maxValue={1}
                                            margin={{ top: 20, right: 110, bottom: 20, left: 110 }}
                                            curve="catmullRomClosed"
                                            borderWidth={2}
                                            borderColor={{ from: 'color' }}
                                            gridLevels={4}
                                            gridShape="circular"
                                            gridLabelOffset={16}
                                            enableDots={true}
                                            dotSize={4}
                                            dotColor={{ theme: 'background' }}
                                            dotBorderWidth={4}
                                            dotBorderColor={{ from: 'color' }}
                                            enableDotLabel={false}
                                            dotLabel="value"
                                            dotLabelYOffset={-12}
                                            colors={{ scheme: 'category10' }}
                                            fillOpacity={0.25}
                                            blendMode="normal"
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            isInteractive={true}
                                            theme={(this.props.theme === 'dark') ? Dark : null}
                                            tooltipFormat={value => ((value - 0.25) * (1 / 0.75)).toFixed(2)}
                                            dotLabelFormat={value => ((value - 0.25) * (1 / 0.75)).toFixed(2)}
                                        />
                                    </div>
                                </Card>
                                <Card style={{ flex: '1 1 auto' }}>
                                    <H3>Rank history</H3>
                                    <HTMLTable style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Rank</th>
                                                <th style={{ textAlign: 'right' }}>Change</th>
                                                {/* <th>Signals (Non-Zero)</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                organization.rank.map((currentRank, rankIndex) => {

                                                    let delta = 0;
                                                    let firstRanking = false;

                                                    if (organization.rank.length > (rankIndex + 1)) {
                                                        delta = organization.rank[rankIndex + 1].index - currentRank.index;
                                                    } else {
                                                        firstRanking = true;
                                                    }
                                                    
                                                    return (
                                                        <tr key={rankIndex}>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{format(new Date(currentRank.timestamp), 'PPP')}</td>
                                                            <td>
                                                                <div style={{ display: 'inline-flex' }}>
                                                                    <span style={{ marginRight: '1em' }}>{currentRank.index + 1}</span>
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {delta !== 0 &&
                                                                    <Tag
                                                                        icon={(delta > 0) ? 'arrow-up' : 'arrow-down'}
                                                                        round={false}
                                                                        minimal={true}
                                                                        intent={(delta > 0) ? Intent.SUCCESS : Intent.DANGER}
                                                                    >
                                                                        {Math.abs(delta)}
                                                                    </Tag>
                                                                }
                                                                {firstRanking &&
                                                                    <Tag
                                                                        icon='notifications'
                                                                        round={false}
                                                                        minimal={true}
                                                                        intent={Intent.PRIMARY}
                                                                    >
                                                                        New
                                                                </Tag>
                                                                }
                                                                {!firstRanking && delta === 0 &&
                                                                    <Tag
                                                                        icon='arrow-left'
                                                                        round={false}
                                                                        minimal={true}
                                                                        intent={Intent.PRIMARY}
                                                                    >
                                                                        {Math.abs(delta)}
                                                                    </Tag>
                                                                }
                                                            </td>
                                                            {/* <td>
                                                            <Tooltip content={signalsText} interactionKind={PopoverInteractionKind.HOVER}>
                                                                <div
                                                                    style={{ maxWidth: '15rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                >
                                                                    {signalsText}
                                                                </div>
                                                            </Tooltip>
                                                        </td> */}
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </HTMLTable>
                                </Card>
                            </>
                        }
                    </div>

                    {organization.signals && organization.signals.input && organization.signals.input.accounts &&
                        <Card style={{ marginTop: '1.5rem', display: 'flex' }}>
                            <div style={{ width: '100%' }}>
                                <H3>Financial statements</H3>
                                <HTMLTable className={Classes.TEXT_MUTED} style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Year</th>
                                            {organization.signals.input.accounts.years.slice(0, 5).map((year, i) =>
                                                <th>{year}</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Operating Revenues</td>
                                            {organization.signals.input.accounts.years.slice(0, 5).map((_year, i) => {
                                                return (
                                                    <td>{(organization.signals.input.accounts.result.driftsinntekter[i]).toLocaleString('no')} NOK</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Injected Capital</td>
                                            {organization.signals.input.accounts.years.slice(0, 5).map((_year, i) => {
                                                return (
                                                    <td>{(organization.signals.input.accounts.balance.innskutt_egenkapital[i]).toLocaleString('no')} NOK</td>
                                                );
                                            })}
                                        </tr>
                                    </tbody>
                                </HTMLTable>
                            </div>
                        </Card>
                    }
                </>
            );
        };

        const error = () => (
            this.state.isLoaded && this.state.error !== null &&
            <div className="banner">
                <NonIdealState
                    icon="error"
                    title={this.state.error.title}
                    description={this.state.error.description}
                />
            </div>
        );

        return (
            <div className="container">
                { !this.state.isLoaded && spinner()}
                { this.state.isLoaded && this.state.error === null && body()}
                { this.state.isLoaded && this.state.error !== null && error()}
            </div >
        );
    }
})