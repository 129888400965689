import React from "react";

import {
    withRouter
} from "react-router-dom";

import { withCookies } from 'react-cookie';

import {
    Icon,
    Button,
    Intent,
    Navbar,
    Spinner,
    Classes,
    MenuItem,
    Alignment,
    NavbarGroup,
    NavbarHeading,
    NavbarDivider
} from "@blueprintjs/core";

import { Omnibar } from "@blueprintjs/select";
import { IconNames } from "@blueprintjs/icons";
import NysnoIcon from "./NysnoIcon";

const SEARCH_DELAY = 250;

export default withRouter(withCookies(class Navigation extends React.Component {

    constructor() {
        super();

        this.state = {
            searchIsOpen: false,
            searchResults: [],
            searchIsLoading: false,
            searchQuery: ''
        };
    }

    searchRequest = setTimeout(() => { }, 0);

    searchQueryChange = query => {

        clearTimeout(this.searchRequest);

        const orgnr = Number(query.replace(/\s+/g, ''));
        let searchIsLoading = false;

        if (isNaN(orgnr)) {

            searchIsLoading = true;
            this.searchRequest = setTimeout(async () => {

                const res = await fetch(`/api/organizations?minimal=true&text=${query}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },

                });

                let organizations = [];
                if (res.ok) {
                    organizations = await res.json();

                }

                this.setState({
                    searchResults: organizations,
                    searchIsLoading: false
                });

            }, SEARCH_DELAY);

        } else {
            const numDigits = Math.max(Math.floor(Math.log10(Math.abs(orgnr))), 0) + 1;
            if (orgnr !== 0 && numDigits === 9) {

                searchIsLoading = true;
                this.searchRequest = setTimeout(async () => {

                    const res = await fetch(`/api/organizations/${orgnr}?minimal=true`, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    let organizations = [];
                    if (res.ok) {
                        organizations.push(await res.json());
                    }

                    this.setState({
                        searchResults: organizations,
                        searchIsLoading: false
                    });

                }, SEARCH_DELAY);

            }
        }

        this.setState({ searchQuery: query, searchIsLoading });
    }

    searchItemRenderer = ({ name, orgnr, rank }, { handleClick, modifiers }) => {
        let text;
        if (rank) {
            text = `${rank[0].index + 1}. ${name}`;
        } else {
            text = `${name}`;
        }

        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={orgnr}
                key={orgnr}
                onClick={handleClick}
                text={text}
            />
        );
    };

    searchHandleClose = () => this.setState({ searchIsOpen: false });
    searchHandleItemSelect = (item) => {
        this.setState({ searchIsOpen: false, searchQuery: '' }, () => {
            this.props.history.push(`/organization/${item.orgnr}`);
        });
    };
    searchHandleOpen = () => this.setState({ searchIsOpen: true });

    render() {
        return (
            <>
                <Navbar>
                    <div className="container">
                        <NavbarGroup align={Alignment.LEFT}>
                            <NavbarHeading>
                                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => this.props.history.push('/')}>
                                    <Icon icon={NysnoIcon} className={Classes.TEXT_MUTED} />
                                    <span>&nbsp;ACDC</span>
                                    {/* &nbsp; <Icon icon="chart" style={{ verticalAlign: 'inherit' }} className={Classes.TEXT_MUTED} /> */}
                                </div>
                            </NavbarHeading>
                        </NavbarGroup>
                        <NavbarGroup align={Alignment.RIGHT}>
                            <Button className={Classes.MINIMAL} rightIcon={IconNames.SEARCH} onClick={this.searchHandleOpen}>Search</Button>
                            <Button className={Classes.MINIMAL} icon={IconNames.HOME} onClick={() => this.props.history.push('/')}>Dashboard</Button>
                            <NavbarDivider />
                            <Button className={Classes.MINIMAL} icon={IconNames.COG} onClick={() => this.props.history.push('/settings')}></Button>
                            <Button className={Classes.MINIMAL} icon={IconNames.LOG_OUT} onClick={() => {
                                this.props.cookies.remove('actix-session');
                            }}>Sign out</Button>
                        </NavbarGroup>
                    </div>
                </Navbar>
                <Omnibar
                    inputProps={{
                        placeholder: 'Search by name or orgnr...',
                        rightElement: (this.state.searchIsLoading ? (<Spinner size={Spinner.SIZE_SMALL} intent={Intent.PRIMARY} />) : null),
                        type: 'search'
                    }}
                    isOpen={this.state.searchIsOpen}
                    itemRenderer={this.searchItemRenderer}
                    items={this.state.searchResults}
                    noResults={<MenuItem disabled={true} text={this.state.searchIsLoading ? 'Searching...' : 'No results.'} />}
                    onItemSelect={this.searchHandleItemSelect}
                    onClose={this.searchHandleClose}
                    onQueryChange={this.searchQueryChange}
                    query={this.state.searchQuery}
                    resetOnQuery={true}
                />
            </>
        );
    }
}));
