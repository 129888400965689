import { Classes } from "@blueprintjs/core";
import React from "react";

export default (
    <span className={Classes.ICON}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16px"
            height="16px"
            viewBox="0 0 96 92"
            version="1.1"
        >
            <defs>
                <clipPath id="clip1">
                    <path d="M 0 0.15625 L 95.078125 0.15625 L 95.078125 92 L 0 92 Z M 0 0.15625 " />
                </clipPath>
            </defs>
            <g id="surface1">
                <g clipPath="url(#clip1)" clipRule="nonzero">
                    <path
                        stroke='none'
                        fillRule='nonzero'
                        fillOpacity={1}
                        d="M 69.375 69.324219 C 59.132812 78.109375 44.3125 78.183594 34.023438 70.242188 C 50.796875 61.859375 64.863281 50.226562 76.136719 35.414062 C 81.820312 46.65625 79.355469 60.765625 69.375 69.324219 Z M 32.898438 26.789062 C 38.171875 22.265625 44.65625 20.050781 51.117188 20.050781 C 56.289062 20.050781 61.4375 21.480469 65.929688 24.273438 C 60.945312 31.171875 55.332031 37.3125 49.113281 42.644531 C 41.980469 48.761719 33.910156 53.925781 24.992188 58.121094 C 20.863281 47.371094 23.667969 34.707031 32.898438 26.789062 Z M 85.710938 20.90625 C 88.84375 15.410156 90.640625 12.058594 93.167969 5.882812 L 79.472656 0.15625 C 77.65625 4.25 76.59375 6.621094 74.382812 10.738281 C 58.519531 0.875 37.429688 1.898438 22.515625 14.6875 C 8.003906 27.132812 3.613281 47.0625 10.152344 63.957031 C 6.855469 65.015625 3.476562 65.96875 0 66.800781 L 3.457031 81.226562 C 8.488281 80.019531 13.339844 78.597656 18.023438 76.96875 C 26.691406 86.898438 38.894531 92 51.167969 92 C 61.300781 92 71.480469 88.527344 79.757812 81.429688 C 97.671875 66.0625 100.160156 39.289062 85.710938 20.90625"
                    />
                </g>
            </g>
        </svg>
    </span>
);