import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import './App.css';

import { Classes, Colors } from '@blueprintjs/core';

import Navigation from './components/Navigation';
import Dashboard from './components/Dashboard';
import Organization from './components/Organization';
import Settings from './components/Settings';
import Login from './components/Login';

import { CookiesProvider } from 'react-cookie';
import { withCookies } from 'react-cookie';

const PrivateRoute = withCookies(function PrivateRoute({ cookies, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={
                ({ location }) => {
                    if (cookies.get('actix-session')) {
                        return (
                            children
                        );
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }}
                            />
                        );
                    };
                }
            }
        />
    );
});

class App extends React.Component {
    constructor({ cookies }) {
        super();

        this.state = {
            theme: cookies.get('theme', { path: '/', secure: true }) || 'dark'
        };
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = (this.state.theme === 'dark') ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY4;
    }

    componentDidUpdate = this.componentDidMount;

    handleSetTheme = theme => {
        console.log(theme);

        this.props.cookies.set('theme', theme, { path: '/', secure: true });
        this.setState({
            theme: this.props.cookies.get('theme', { path: '/', secure: true }) || 'dark'
        });
    };

    render() {

        let session = null;
        const sessionPayload = this.props.cookies.get('actix-session');
        if (sessionPayload) {
            session = JSON.parse(sessionPayload.slice(sessionPayload.indexOf('=') + 1));

            if (session.level && session.username) {
                session.level = parseInt(session.level);
                session.username = session.username.replace(/"*/g, '');
            } else {
                this.props.cookies.remove('actix-session');
            }
        }

        return (
            <CookiesProvider>
                <Router>
                    <div
                        style={{
                            minHeight: "100vh"
                        }}
                        className={(this.state.theme === 'dark') ? Classes.DARK : null}
                    >
                        <Switch>
                            <Route path="/login">
                                <Login />
                            </Route>

                            <PrivateRoute path="/settings">
                                <Navigation />
                                <Settings theme={this.state.theme} onSetTheme={this.handleSetTheme} session={session}/>
                            </PrivateRoute>
                            <Route
                                path="/organization/:orgnr"
                                render={
                                    ({ location, match }) => {
                                        if (this.props.cookies.get('actix-session')) {
                                            return (
                                                <>
                                                    <Navigation />
                                                    <Organization key={match.params.orgnr} theme={this.state.theme} />
                                                </>
                                            );
                                        } else {
                                            return (
                                                <Redirect
                                                    to={{
                                                        pathname: "/login",
                                                        state: { from: location }
                                                    }}
                                                />
                                            );
                                        };
                                    }
                                }
                            />
                            <PrivateRoute path="/">
                                <Navigation />
                                <Dashboard />
                            </PrivateRoute>
                        </Switch>
                    </div>
                </Router>
            </CookiesProvider>
        );
    }
}

export default withCookies(App);
