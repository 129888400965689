import React from "react";
import {
    H2,
    H3,
    Card,
    Button,
    Switch,
    Intent,
    Spinner,
    Classes,
    Divider,
    TagInput,
    Collapse,
    FileInput,
    FormGroup,
    Alignment,
    HTMLSelect,
    ProgressBar,
    AnchorButton,
    ControlGroup,
    NumericInput,
    NonIdealState,
    H1,
    HTMLTable,
    Tooltip,
    InputGroup,
    Popover,
    H5,
    Dialog
} from "@blueprintjs/core";
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { IconNames } from "@blueprintjs/icons";
import { AppToaster } from "./AppToaster";
import * as ease from 'd3-ease';

const MATCH_SIC_CODE = new RegExp(/(^[0-9]{1,2}$)|(^[0-9]{2}.[0-9]{1,3}$)/g);

function RecencyControls({ label, recencyEasingFunction, onFunctionNameChange, onFunctionExponentChange, recencyFalloff, onFalloffChange }) {
    return (
        <React.Fragment>
            <FormGroup
                label="Recency easing function"
                labelFor={label + "-recency-easing-function-input"}
                helperText={<span>Easing function used when calculating recency (<a href="https://github.com/d3/d3-ease">documentation</a>).</span>}
            >
                <ControlGroup fill={false} vertical={false}>
                    <HTMLSelect
                        id={label + "-recency-easing-function-input"}
                        value={recencyEasingFunction.name}
                        onChange={(event) => onFunctionNameChange(event.target.value)}
                    >
                        {Object.keys(ease).map(name => <option key={name} value={name}>{name.slice('ease'.length).split(/(?=[A-Z])/).join(' ')}</option>)}
                    </HTMLSelect>
                    {ease[recencyEasingFunction.name].exponent &&
                        <NumericInput
                            placeholder="Exponent (optional)"
                            defaultValue={recencyEasingFunction.exponent ? recencyEasingFunction.exponent : undefined}
                            onValueChange={(value, string) => {
                                if (string !== '' && !isNaN(value)) {
                                    onFunctionExponentChange(value)
                                }
                            }}
                        />
                    }
                </ControlGroup>
            </FormGroup>
            <FormGroup
                label="Recency falloff"
                labelInfo="(in months, required)"
                labelFor={label + "-recency-falloff-input"}
                helperText="Anything older than this will not contribute to the calculated score."
            >
                <NumericInput
                    id={label + "-recency-falloff-input"}
                    placeholder="Months"
                    defaultValue={recencyFalloff}
                    onValueChange={(value, string) => {
                        if (string !== '' && !isNaN(value)) {
                            onFalloffChange(value);
                        }
                    }}
                />
            </FormGroup>
        </React.Fragment>
    );
};

class CollapsiblePanel extends React.Component {
    constructor() {
        super();

        this.state = {
            isOpen: false
        }
    }

    render() {
        return (
            <Card style={this.props.style}>
                <AnchorButton
                    style={{ outline: 'none' }}
                    icon={this.state.isOpen ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT}
                    large={true}
                    minimal={true}
                    fill={true}
                    alignText={Alignment.LEFT}
                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                >
                    {this.props.title}
                </AnchorButton>
                <Collapse isOpen={this.state.isOpen}>
                    <div style={{ padding: '1rem' }}>
                        <p className={Classes.TEXT_MUTED}>{this.props.description}</p>
                        {this.props.children}
                    </div>
                </Collapse>
            </Card>
        );
    }
}


export default withCookies(withRouter(class Settings extends React.Component {

    constructor() {
        super();

        this.state = {
            isLoaded: false,
            error: null,
            settings: null,
            users: null,
            modifications: null,
            importRatingsFile: null,
            importRatingsSwitchChecked: true,

            isAddUserDialogOpen: false,
            addUserDialog: {
                username: null,
                password: null,
                level: 1,
                showPassword: false
            }
        };
    }

    componentDidMount() {
        if (this.props.session && this.props.session.level > 1) {
            this.fetchSettings();
        }

        this.fetchUsers();
    }

    fetchSettings = async () => {
        try {
            let res = await fetch(`/api/settings`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (res.ok) {
                const settings = await res.json();

                this.setState({
                    isLoaded: true,
                    settings,
                });

            } else if (res.status === 401) {
                this.props.history.push({ pathname: '/login', state: { referrer: this.props.location } });
                return null;
            } else {
                this.setState({
                    isLoaded: true,
                    error: {
                        title: res.status,
                        description: res.statusText
                    }
                });
            }

        } catch (err) {
            this.setState({
                isLoaded: true,
                error: {
                    title: "Network error",
                    description: err.message
                }
            });
        }
    }

    fetchUsers = async () => {
        try {
            let res = await fetch(`/api/users`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (res.ok) {
                const users = await res.json();

                this.setState({
                    isLoaded: true,
                    users,
                    modifications: {
                        username: users.map(() => null),
                        password: users.map(() => null),
                        showPassword: users.map(() => false)
                    }
                });

            } else if (res.status === 401) {
                this.props.history.push({ pathname: '/login', state: { referrer: this.props.location } });
                return null;
            } else {
                this.setState({
                    isLoaded: true,
                    error: {
                        title: res.status,
                        description: res.statusText
                    }
                });
            }

        } catch (err) {
            this.setState({
                isLoaded: true,
                error: {
                    title: "Network error",
                    description: err.message
                }
            });
        }
    }

    setRating = async (orgnr, score) => {
        let res = await fetch(`/api/organizations/${orgnr}/rating`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({ score })
        });

        if (!res.ok) {
            throw Error(`${res.status}`);
        }
    }

    handleImportButtonClicked = async () => {
        if (this.state.importRatingsFile) {

            let orgnrs = (await this.state.importRatingsFile.text()).split('\n').map(t => parseInt(t.trim())).filter(v => v);

            const key = AppToaster.show({
                icon: IconNames.CLOUD_UPLOAD,
                message: (
                    <ProgressBar
                        intent={Intent.PRIMARY}
                        value={0 / orgnrs.length}
                    />
                ),
                timeout: 0
            });

            let dismiss = false;

            let errorOther = 0;
            let errorNotFound = 0;

            for (const [i, orgnr] of orgnrs.entries()) {
                if (dismiss) {
                    break;
                }

                try {
                    await this.setRating(orgnr, this.state.importRatingsSwitchChecked ? 1.0 : 0.0);
                    AppToaster.show({
                        icon: IconNames.CLOUD_UPLOAD,
                        message: (
                            <ProgressBar
                                intent={i < orgnrs.length ? Intent.PRIMARY : Intent.SUCCESS}
                                value={i / orgnrs.length}
                            />
                        ),
                        timeout: 0,
                        // eslint-disable-next-line no-loop-func
                        onDismiss: () => { dismiss = true; }
                    }, key);
                } catch (error) {
                    if (error.message === '404') {
                        errorNotFound += 1;
                    } else {
                        errorOther += 1;
                    }
                    console.error(error.message);
                }
            }

            if (!dismiss) {
                AppToaster.show({
                    icon: IconNames.TICK_CIRCLE,
                    message: `${orgnrs.length - (errorNotFound + errorOther)} ratings imported. ${(errorNotFound !== 0 && errorOther === 0) ? `${errorNotFound} of the organizations were not found.` : `Encountered ${errorNotFound + errorOther} errors while importing. `}`,
                    intent: Intent.SUCCESS,
                    timeout: 5000
                }, key);
            }
        }
    };

    handleSaveButtonClicked = async () => {
        try {
            let res = await fetch(`/api/settings`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.settings)
            });

            if (res.ok) {
                AppToaster.show({ icon: 'tick-circle', message: 'Settings saved!', intent: Intent.SUCCESS, timeout: 2500 });
            } else {
                AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to save settings.` });
            }

            await this.fetchSettings();
        } catch (err) {
            console.err(err);
            AppToaster.show({ icon: 'error', message: `Network error. Unable to save settings.` });
        }

        this.setState({ isFresh: false });
    }

    handleRecencyFunctionNameChange = (key, name) => {
        const settings = this.state.settings;
        this.setState({
            settings: {
                ...settings,
                signals: {
                    ...settings.signals,
                    [key]: {
                        ...settings.signals[key],
                        recencyEasingFunction: {
                            ...settings.signals[key].recencyEasingFunction,
                            name
                        }
                    }
                }
            }
        });
    };

    handleRecencyExponentChange = (key, exponent) => {
        const settings = this.state.settings;
        this.setState({
            settings: {
                ...settings,
                signals: {
                    ...settings.signals,
                    [key]: {
                        ...settings.signals[key],
                        recencyEasingFunction: {
                            ...settings.signals[key].recencyEasingFunction,
                            exponent
                        }
                    }
                }
            }
        });
    };

    handleRecencyFalloffChange = (key, value) => {
        const settings = this.state.settings;
        this.setState({
            settings: {
                ...settings,
                signals: {
                    ...settings.signals,
                    [key]: {
                        ...settings.signals[key],
                        recencyFalloff: value
                    }
                }
            }
        });
    };

    handleCreateUser = async (username, password, level) => {
        try {
            let res = await fetch(`/api/users/${username}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password, level })
            });

            if (res.ok) {
                AppToaster.show({ icon: 'tick-circle', message: 'User created!', intent: Intent.SUCCESS, timeout: 2500 });
            } else {
                AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to create user.` });
            }

            await this.fetchSettings();
        } catch (err) {
            console.err(err);
            AppToaster.show({ icon: 'error', message: `Network error. Unable to create user.` });
        }

        this.handleAddUserDialogClose();
    }

    handleAddUserDialogClose = () => this.setState({
        isAddUserDialogOpen: false,
        addUserDialog: {
            username: null,
            password: null,
            level: 1,
            showPassword: false
        }
    });

    handleSetUsername = async (user, modifications, index = null, isCurrentUser = false) => {
        if (
            modifications.username &&
            modifications.username.length >= 3 &&
            modifications.username !== user.username &&
            (index === null || (this.state.users.some((user, i) => modifications.username === user.username && i !== index) === false))
        ) {
            try {
                let res = await fetch(`/api/users/${user.username}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...user, username: modifications.username })
                });

                if (!res.ok) {
                    AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to save username.` });
                } else if (isCurrentUser) {
                    AppToaster.show({ icon: IconNames.TICK_CIRCLE, intent: Intent.SUCCESS, message: `Username was successfully changed to "${modifications.username}". Sign in to continue.` });
                }

                await this.fetchSettings();
            } catch (err) {
                console.err(err);
                AppToaster.show({ icon: 'error', message: `Network error. Unable to save username.` });
            }
        }
    };

    handleSetPassword = async (user, modifications) => {
        if (
            modifications.password &&
            modifications.password.length > 8
        ) {
            try {
                let res = await fetch(`/api/users/${user.username}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...user, password: modifications.password })
                });

                if (!res.ok) {
                    AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to save password.` });
                }

                await this.fetchSettings();
            } catch (err) {
                console.err(err);
                AppToaster.show({ icon: 'error', message: `Network error. Unable to save password.` });
            }
        }
    };

    handleSetLevel = async (user, level) => {
        try {
            let res = await fetch(`/api/users/${user.username}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...user, level })
            });

            if (!res.ok) {
                AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to save role.` });
            }

            await this.fetchSettings();
        } catch (err) {
            console.err(err);
            AppToaster.show({ icon: 'error', message: `Network error. Unable to save role.` });
        }
    };

    render() {
        const spinner = () => (
            <div className="banner">
                <Spinner size={64} />
            </div>
        );

        const body = () => {

            return (
                <div style={{ paddingBottom: '2.5rem' }}>
                    <div className="group" style={{ marginTop: '2rem', marginBottom: '1.5rem' }}>
                        <div>
                            <H1>Settings</H1>
                        </div>
                    </div>

                    <Card style={{ marginBottom: '1.5rem' }}>
                        <H2>Theme</H2>
                        <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />

                        <FormGroup
                            label="Theme"
                            labelInfo="(light/dark mode)"
                        >
                            <Switch
                                checked={this.props.theme === 'dark'}
                                label='Dark mode'
                                onChange={event => {
                                    this.props.onSetTheme(event.target.checked ? 'dark' : 'light');
                                }}
                            />
                        </FormGroup>
                    </Card>

                    <Card style={{ marginBottom: '1.5rem' }}>
                        <H2>Import ratings</H2>
                        <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />

                        <p className={Classes.TEXT_MUTED} style={{ textAlign: 'justify', maxWidth: '72ch' }}>You can import ratings from a CSV-file. The CSV-file must consist of only a single column specifying the orgnrs of the companies / organizations.</p>
                        <p className={Classes.TEXT_MUTED} style={{ textAlign: 'justify', maxWidth: '72ch' }}>The ratings will be applied to the companies / organizations with their current signal radar. If, at a later point in time, you want to update their ratings to reflect their new signal radar you can import them again.</p>

                        <FormGroup
                            label="File to import"
                            labelInfo="(CSV-format)"
                            labelFor="file-input"
                        >
                            <FileInput id="file-input" fill={true} text={this.state.importRatingsFile ? this.state.importRatingsFile.name : 'Choose file...'} inputProps={{ accept: '.csv' }} onInputChange={e => this.setState({ importRatingsFile: e.target.files[0] })} />
                        </FormGroup>

                        <FormGroup
                            label="Rating of companies / organizations"
                            labelFor="rating-input"
                        >
                            <Switch id="rating-input" label={this.state.importRatingsSwitchChecked ? 'Very interesting' : 'Not interesting'} checked={this.state.importRatingsSwitchChecked} onChange={e => this.setState({ importRatingsSwitchChecked: e.target.checked })} />
                        </FormGroup>

                        <Button onClick={() => this.handleImportButtonClicked()}>Import ratings</Button>
                    </Card>

                    {this.props.session && this.props.session.level >= 1 && this.state.users &&
                        <Card style={{ marginBottom: '1.5rem' }}>
                            <H2>Manage user account{this.state.users.length > 1 && 's'}</H2>
                            {(this.state.users.length > 1)
                                ? <p className={Classes.TEXT_MUTED}>Administrators have access to settings and can add/delete user accounts.</p>
                                : <p className={Classes.TEXT_MUTED}>You can modify your username and password.</p>
                            }

                            <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />

                            <HTMLTable style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Username</th>
                                        <th>Role</th>
                                        <th>Change password</th>
                                        <th style={{ textAlign: 'right' }}>
                                            {this.props.session.level >= 10 &&
                                                <>
                                                    <Button
                                                        intent={Intent.PRIMARY}
                                                        onClick={() => this.setState({ isAddUserDialogOpen: true })}
                                                    >
                                                        Create user
                                                    </Button>
                                                    <Dialog
                                                        icon={IconNames.PERSON}
                                                        isOpen={this.state.isAddUserDialogOpen}
                                                        onClose={this.handleAddUserDialogClose}
                                                        title="Create user"
                                                    >
                                                        <div className={Classes.DIALOG_BODY}>
                                                            <FormGroup
                                                                label="Username"
                                                                labelInfo="(required)"
                                                                labelFor="username-input"
                                                                helperText={this.state.users.some(({ username }) => this.state.addUserDialog.username === username) ? "User with specified username already exists." : null}
                                                            >
                                                                <InputGroup
                                                                    type="text"
                                                                    id="username-input"
                                                                    placeholder="Enter username .."
                                                                    intent={(this.state.users.some(({ username }) => this.state.addUserDialog.username === username) || (this.state.addUserDialog.username && this.state.addUserDialog.username.length < 3)) ? Intent.DANGER : Intent.NONE}
                                                                    onChange={event => this.setState({
                                                                        addUserDialog: {
                                                                            ...this.state.addUserDialog,
                                                                            username: event.target.value
                                                                        }
                                                                    })}
                                                                />
                                                            </FormGroup>

                                                            <FormGroup
                                                                label="Password"
                                                                labelInfo="(required)"
                                                                labelFor="password-input"
                                                            >
                                                                <InputGroup
                                                                    id="password-input"
                                                                    type={this.state.addUserDialog.showPassword ? "text" : "password"}
                                                                    placeholder="Enter password .."
                                                                    intent={(this.state.addUserDialog.password && this.state.addUserDialog.password.length < 9) ? Intent.DANGER : Intent.NONE}
                                                                    rightElement={
                                                                        <Tooltip content={`Show password`}>
                                                                            <Button
                                                                                icon={this.state.addUserDialog.showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                                                                                intent={Intent.PRIMARY}
                                                                                minimal={true}
                                                                                onClick={() => this.setState({
                                                                                    addUserDialog: {
                                                                                        ...this.state.addUserDialog,
                                                                                        showPassword: !this.state.addUserDialog.showPassword
                                                                                    }
                                                                                })}
                                                                            />
                                                                        </Tooltip>
                                                                    }
                                                                    onChange={event => this.setState({
                                                                        addUserDialog: {
                                                                            ...this.state.addUserDialog,
                                                                            password: event.target.value
                                                                        }
                                                                    })}
                                                                />
                                                            </FormGroup>

                                                            <FormGroup
                                                                label="Role"
                                                                labelFor="role-input"
                                                            >
                                                                <HTMLSelect
                                                                    id="role-input"
                                                                    defaultValue={this.state.addUserDialog.level}
                                                                    onChange={event => this.setState({
                                                                        addUserDialog: {
                                                                            ...this.state.addUserDialog,
                                                                            level: event.target.value
                                                                        }
                                                                    })}
                                                                >
                                                                    <option value={1}>User</option>
                                                                    <option value={10}>Admin</option>
                                                                </HTMLSelect>
                                                            </FormGroup>
                                                        </div>
                                                        <div className={Classes.DIALOG_FOOTER}>
                                                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                                <Button onClick={this.handleAddUserDialogClose}>Cancel</Button>
                                                                <Button
                                                                    disabled={
                                                                        (this.state.users.some(({ username }) => this.state.addUserDialog.username === username)) ||
                                                                        (!this.state.addUserDialog.username || this.state.addUserDialog.username.length < 3) ||
                                                                        (!this.state.addUserDialog.password || this.state.addUserDialog.password.length < 9)
                                                                    }
                                                                    intent={Intent.PRIMARY}
                                                                    onClick={() => this.handleCreateUser(this.state.addUserDialog.username, this.state.addUserDialog.password, parseInt(this.state.addUserDialog.level))}
                                                                >
                                                                    Create
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Dialog>
                                                </>
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{
                                    this.state.users.map((user, index) => {

                                        const isCurrentUser = this.props.session.username === user.username;

                                        const modifications = {
                                            username: this.state.modifications.username[index],
                                            password: this.state.modifications.password[index],
                                            showPassword: this.state.modifications.showPassword[index]
                                        };

                                        const deleteUser = async () => {
                                            try {
                                                let res = await fetch(`/api/users/${user.username}`, {
                                                    method: 'DELETE',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                    }
                                                });

                                                if (!res.ok) {
                                                    AppToaster.show({ icon: 'error', message: `${res.statusText}. Unable to delete user.` });
                                                }

                                                await this.fetchSettings();
                                            } catch (err) {
                                                console.err(err);
                                                AppToaster.show({ icon: 'error', message: `Network error. Unable to delete user.` });
                                            }
                                        };

                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <ControlGroup vertical={false}>
                                                        <InputGroup
                                                            type="text"
                                                            defaultValue={user.username}
                                                            placeholder="Enter new username .."
                                                            intent={this.state.users.some((user, i) => modifications.username === user.username && i !== index) ? Intent.DANGER : Intent.NONE}
                                                            onChange={event => {
                                                                this.setState({
                                                                    modifications: {
                                                                        ...this.state.modifications,
                                                                        username: this.state.modifications.username.map((v, i) => i === index ? event.target.value : v)
                                                                    }
                                                                });
                                                            }}
                                                            onKeyPress={({ key }) => (key === 'Enter') && this.handleSetUsername(user, modifications, index, isCurrentUser)}
                                                        />
                                                        <Tooltip content={`Save username`} disabled={!(
                                                            modifications.username &&
                                                            modifications.username.length >= 3 &&
                                                            modifications.username !== user.username &&
                                                            this.state.users.some((user, i) => modifications.username === user.username && i !== index) === false
                                                        )}>
                                                            <Button
                                                                icon={IconNames.TICK}
                                                                intent={Intent.NONE}
                                                                disabled={!(
                                                                    modifications.username &&
                                                                    modifications.username.length >= 3 &&
                                                                    modifications.username !== user.username &&
                                                                    this.state.users.some((user, i) => modifications.username === user.username && i !== index) === false
                                                                )}
                                                                minimal={false}
                                                                onClick={() => this.handleSetUsername(user, modifications, index, isCurrentUser)}
                                                            />
                                                        </Tooltip>
                                                    </ControlGroup>
                                                </td>
                                                <td>
                                                    <HTMLSelect
                                                        defaultValue={user.level}
                                                        onChange={event => this.handleSetLevel(user, parseInt(event.target.value))}
                                                        disabled={isCurrentUser}
                                                    >
                                                        <option value={1}>User</option>
                                                        <option value={10}>Admin</option>
                                                    </HTMLSelect>
                                                </td>
                                                <td>
                                                    <ControlGroup vertical={false}>
                                                        <InputGroup
                                                            type={modifications.showPassword ? "text" : "password"}
                                                            placeholder="Enter new password .."
                                                            defaultValue={modifications.password}
                                                            inputRef={ref => this[`passwordInputRef${index}`] = ref}
                                                            rightElement={
                                                                <Tooltip content={`Show password`}>
                                                                    <Button
                                                                        icon={modifications.showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                                                                        intent={Intent.PRIMARY}
                                                                        minimal={true}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                modifications: {
                                                                                    ...this.state.modifications,
                                                                                    showPassword: this.state.modifications.showPassword.map((v, i) => i === index ? !modifications.showPassword : v)
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                            onChange={event => {
                                                                this.setState({
                                                                    modifications: {
                                                                        ...this.state.modifications,
                                                                        password: this.state.modifications.password.map((v, i) => i === index ? event.target.value : v)
                                                                    }
                                                                });
                                                            }}
                                                            onKeyPress={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.handleSetPassword(user, modifications).then(() => {
                                                                        event.target.value = '';
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Tooltip content={`Save password`} disabled={!modifications.password || modifications.password.length < 9}>
                                                            <Button
                                                                icon={IconNames.TICK}
                                                                intent={Intent.NONE}
                                                                disabled={!modifications.password || modifications.password.length < 9}
                                                                minimal={false}
                                                                onClick={() => {
                                                                    this.handleSetPassword(user, modifications).then(() => {
                                                                        this[`passwordInputRef${index}`].value = '';
                                                                    });
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </ControlGroup>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
                                                        <Button rightIcon={IconNames.CROSS} minimal={true} disabled={isCurrentUser}>Delete</Button>
                                                        <div key="text">
                                                            <H5>Confirm deletion</H5>
                                                            <p>Are you sure you want to delete this user?<br />You won't be able to recover it.</p>
                                                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                                                                <Button style={{ marginRight: 10 }} className={Classes.POPOVER_DISMISS}>
                                                                    Cancel
                                                                </Button>
                                                                <Button intent={Intent.DANGER} className={Classes.POPOVER_DISMISS} onClick={deleteUser}>
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Popover>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }</tbody>
                            </HTMLTable>
                        </Card>
                    }

                    {this.props.session && this.props.session.level > 1 && this.state.settings && this.state.users &&

                        <Card style={{ marginBottom: '1.5rem' }}>
                            <H2>Advanced settings</H2>

                            <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />

                            <H3>Brreg</H3>
                            <p className={Classes.TEXT_MUTED}>These settings can be used to restrict the number of companies / organizations that are imported from Brreg.</p>

                            <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />

                            <FormGroup
                                label="Minimum number of employees"
                                labelInfo="(required)"
                                labelFor="headcount-min-input"
                            >
                                <NumericInput
                                    id="headcount-min-input"
                                    fill={true}
                                    placeholder="Minimum headcount"
                                    value={this.state.settings.brreg.headcountMin}
                                    onValueChange={value => {
                                        const settings = this.state.settings;
                                        this.setState({
                                            settings: {
                                                ...settings,
                                                brreg: {
                                                    ...settings.brreg,
                                                    headcountMin: parseInt(value)
                                                }
                                            }
                                        });
                                    }}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Maximum number of employees"
                                labelInfo="(required)"
                                labelFor="headcount-max-input"
                            >
                                <NumericInput
                                    id="headcount-max-input"
                                    fill={true}
                                    placeholder="Maximum headcount"
                                    value={this.state.settings.brreg.headcountMax}
                                    onValueChange={value => {
                                        const settings = this.state.settings;
                                        this.setState({
                                            settings: {
                                                ...settings,
                                                brreg: {
                                                    ...settings.brreg,
                                                    headcountMax: parseInt(value)
                                                }
                                            }
                                        });
                                    }}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Standard Industrial Classification (SIC 2007) filter"
                                labelFor="sic-code-input"
                                helperText={<span>The SIC-codes to exclude when sourcing organizations / companies from Brreg. Examples: 0, 01, 01.2, 01.23, 01.234. (<a href="https://www.ssb.no/klass/klassifikasjoner/6/koder">SIC 2007 documentation</a>)</span>}
                            >
                                <TagInput
                                    id="sic-code-input"
                                    addOnBlur={true}
                                    addOnPaste={false}
                                    tagProps={{ minimal: true }}
                                    onChange={inputs => {
                                        const list = inputs.map(value => value.trim()).filter(value => value.match(MATCH_SIC_CODE));
                                        const settings = this.state.settings;
                                        this.setState({
                                            settings: {
                                                ...settings,
                                                brreg: {
                                                    ...settings.brreg,
                                                    sicFilterList: list
                                                }
                                            }
                                        });
                                    }}
                                    values={this.state.settings.brreg.sicFilterList}
                                />
                            </FormGroup>

                            <Button intent={Intent.SUCCESS} onClick={() => this.handleSaveButtonClicked()}>Save settings</Button>

                            <H3 style={{ marginTop: '2.5rem' }}>Signals</H3>
                            <p className={Classes.TEXT_MUTED}>You can adjust how various signals are calculated.</p>

                            <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />

                            <CollapsiblePanel
                                style={{ padding: '0', marginBottom: '1.5rem' }}
                                title="Enova"
                                description="Grants are separated by role; supplier and owner. The scores are calculated from the sum of grants given, weighted by recency."
                            >
                                <RecencyControls
                                    label="enova"
                                    recencyEasingFunction={this.state.settings.signals.enova.recencyEasingFunction}
                                    recencyFalloff={this.state.settings.signals.enova.recencyFalloff}
                                    onFunctionNameChange={name => this.handleRecencyFunctionNameChange('enova', name)}
                                    onFunctionExponentChange={exponent => this.handleRecencyExponentChange('enova', exponent)}
                                    onFalloffChange={value => this.handleRecencyFalloffChange('enova', value)}
                                />

                                <Button intent={Intent.SUCCESS} onClick={() => this.handleSaveButtonClicked()}>Save settings</Button>
                            </CollapsiblePanel>

                            <CollapsiblePanel
                                style={{ padding: '0', marginBottom: '1.5rem' }}
                                title="Innovation Norway"
                                description="Calculated from the sum of grants given, weighted by recency and category."
                            >
                                <RecencyControls
                                    label="innovationNorway"
                                    recencyEasingFunction={this.state.settings.signals.innovationNorway.recencyEasingFunction}
                                    recencyFalloff={this.state.settings.signals.innovationNorway.recencyFalloff}
                                    onFunctionNameChange={name => this.handleRecencyFunctionNameChange('innovationNorway', name)}
                                    onFunctionExponentChange={exponent => this.handleRecencyExponentChange('innovationNorway', exponent)}
                                    onFalloffChange={value => this.handleRecencyFalloffChange('innovationNorway', value)}
                                />

                                <Button intent={Intent.SUCCESS} onClick={() => this.handleSaveButtonClicked()}>Save settings</Button>
                            </CollapsiblePanel>

                            <CollapsiblePanel
                                style={{ padding: '0', marginBottom: '1.5rem' }}
                                title="The Research Council of Norway (RCN)"
                                description="Calculated from the monetary sum of grants, weighted by recency and activity code."
                            >
                                <RecencyControls
                                    label="rcn"
                                    recencyEasingFunction={this.state.settings.signals.rcn.recencyEasingFunction}
                                    recencyFalloff={this.state.settings.signals.rcn.recencyFalloff}
                                    onFunctionNameChange={name => this.handleRecencyFunctionNameChange('rcn', name)}
                                    onFunctionExponentChange={exponent => this.handleRecencyExponentChange('rcn', exponent)}
                                    onFalloffChange={value => this.handleRecencyFalloffChange('rcn', value)}
                                />

                                <FormGroup
                                    label="Relevant activity codes"
                                    labelFor="rcn-activity-codes-input"
                                    helperText={<span>Specified activity codes are weighted twice as much as the others.</span>}
                                >
                                    <TagInput
                                        id="rcn-activity-codes-input"
                                        addOnBlur={true}
                                        addOnPaste={false}
                                        tagProps={{ minimal: true }}
                                        onChange={inputs => {
                                            const list = inputs.map(value => value.trim());
                                            const settings = this.state.settings;
                                            this.setState({
                                                settings: {
                                                    ...settings,
                                                    signals: {
                                                        ...settings.signals,
                                                        rcn: {
                                                            ...settings.signals.rcn,
                                                            relevantActivityCodes: list
                                                        }
                                                    }
                                                }
                                            });
                                        }}
                                        values={this.state.settings.signals.rcn.relevantActivityCodes}
                                    />
                                </FormGroup>

                                <Button intent={Intent.SUCCESS} onClick={() => this.handleSaveButtonClicked()}>Save settings</Button>
                            </CollapsiblePanel>

                            <CollapsiblePanel
                                style={{ padding: '0', marginBottom: '1.5rem' }}
                                title="NAV"
                                description="Calculated from the number and recency of job ads in the NAV-registry."
                            >
                                <RecencyControls
                                    label="nav"
                                    recencyEasingFunction={this.state.settings.signals.nav.recencyEasingFunction}
                                    recencyFalloff={this.state.settings.signals.nav.recencyFalloff}
                                    onFunctionNameChange={name => this.handleRecencyFunctionNameChange('nav', name)}
                                    onFunctionExponentChange={exponent => this.handleRecencyExponentChange('nav', exponent)}
                                    onFalloffChange={value => this.handleRecencyFalloffChange('nav', value)}
                                />

                                <Button intent={Intent.SUCCESS} onClick={() => this.handleSaveButtonClicked()}>Save settings</Button>
                            </CollapsiblePanel>

                            <H3 style={{ marginTop: '2.5rem' }}>Ranking</H3>
                            <p className={Classes.TEXT_MUTED}>These settings can be adjusted to improve the ranking algorithm. Warning! Invalid parameters will cause the ranking algorithm to stop functioning.</p>

                            <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />

                            <CollapsiblePanel
                                style={{ padding: '0', marginBottom: '1.5rem' }}
                                title="Neural network"
                                description=""
                            >
                                <FormGroup
                                    label="Neural network activation function"
                                    labelFor="neural-network-activation-input"
                                    helperText={<span>Activation function for the neural network (<a href="https://www.wikiwand.com/en/Activation_function">explanations here</a>).</span>}
                                >
                                    <HTMLSelect
                                        id="neural-network-activation-input"
                                        value={this.state.settings.ranking.neuralNetworkParameters.activation}
                                        onChange={event => {
                                            const settings = this.state.settings;
                                            this.setState({
                                                settings: {
                                                    ...settings,
                                                    ranking: {
                                                        ...settings.ranking,
                                                        neuralNetworkParameters: {
                                                            ...settings.ranking.neuralNetworkParameters,
                                                            activation: event.target.value
                                                        }
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        <option value="sigmoid">Sigmoid</option>
                                        <option value="relu">Relu</option>
                                        <option value="tanh">Tanh</option>
                                    </HTMLSelect>
                                </FormGroup>
                                <FormGroup
                                    label="Neural network hidden layers"
                                    labelInfo="(optional)"
                                    labelFor="neural-network-hidden-layers-input"
                                    helperText={<span>The number of hidden layers and the size of each layer (<a href="https://github.com/BrainJS/brain.js#hiddenlayers">documentation</a>).</span>}
                                >
                                    <TagInput
                                        id="neural-network-hidden-layers-input"
                                        addOnBlur={true}
                                        addOnPaste={false}
                                        tagProps={{ minimal: true }}
                                        onChange={inputs => {
                                            const list = inputs.map(value => value.trim());
                                            const settings = this.state.settings;
                                            this.setState({
                                                settings: {
                                                    ...settings,
                                                    ranking: {
                                                        ...settings.ranking,
                                                        neuralNetworkParameters: {
                                                            ...settings.ranking.neuralNetworkParameters,
                                                            hiddenLayers: list
                                                        }
                                                    }
                                                }
                                            });
                                        }}
                                        values={this.state.settings.ranking.neuralNetworkParameters.hiddenLayers}
                                    />
                                </FormGroup>

                                <Button intent={Intent.SUCCESS} onClick={() => this.handleSaveButtonClicked()}>Save settings</Button>
                            </CollapsiblePanel>

                            <CollapsiblePanel
                                style={{ padding: '0', marginBottom: '1.5rem' }}
                                title="Text classifier"
                                description=""
                            >
                                <FormGroup
                                    label="Text classifier parameters"
                                    labelInfo="(optional)"
                                    labelFor="text-classifier-input"
                                    helperText={<span>Parameters for the fastText text classifier (<a href="https://fasttext.cc/docs/en/options.html">documentation</a>).</span>}
                                >
                                    <TagInput
                                        id="text-classifier-input"
                                        addOnBlur={true}
                                        addOnPaste={false}
                                        tagProps={{ minimal: true }}
                                        onChange={inputs => {
                                            const list = inputs.map(value => value.trim());
                                            const settings = this.state.settings;
                                            this.setState({
                                                settings: {
                                                    ...settings,
                                                    ranking: {
                                                        ...settings.ranking,
                                                        textClassifierParameters: list
                                                    }
                                                }
                                            });
                                        }}
                                        values={this.state.settings.ranking.textClassifierParameters}
                                    />
                                </FormGroup>

                                <Button intent={Intent.SUCCESS} onClick={() => this.handleSaveButtonClicked()}>Save settings</Button>
                            </CollapsiblePanel>
                        </Card>
                    }
                </div>
            );
        };

        const error = () => (
            this.state.isLoaded && this.state.error !== null &&
            <div className="banner">
                <NonIdealState
                    icon="error"
                    title={this.state.error.title}
                    description={this.state.error.description}
                />
            </div>
        );

        return (
            <div className="container">
                { !this.state.isLoaded && spinner()}
                { this.state.isLoaded && this.state.error === null && body()}
                { this.state.isLoaded && this.state.error !== null && error()}
            </div >
        );
    }
}))