import React from "react";
import {
    H1,
    Card,
    Button,
    Intent,
    Classes,
    FormGroup,
    InputGroup,
    Tooltip,
    Colors
} from "@blueprintjs/core";
import { Redirect, withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';

export default withRouter(withCookies(class Login extends React.Component {

    constructor(props) {
        super();

        props.cookies.remove('actix-session');

        this.state = {
            error: false,
            showPassword: false,

            signedIn: false,
            username: '',
            password: ''
        };
    }

    passwordInput = null;

    handleLockClick = () => this.setState({ showPassword: !this.state.showPassword });

    handleLoginButtonClicked = async () => {
        try {
            let res = await fetch(`/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: this.state.username,
                    password: this.state.password
                })
            });

            if (res.ok) {
                this.setState({ signedIn: true });
            } else {
                this.setState({ error: true });
            }
        } catch (err) {
            console.error(err);
            this.setState({ error: true });
        }
    }

    render() {
        const lockButton = (
            <Tooltip content={`${this.state.showPassword ? "Hide" : "Show"} Password`}>
                <Button
                    icon={this.state.showPassword ? "unlock" : "lock"}
                    intent={Intent.NONE}
                    minimal={true}
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        );

        if (this.state.signedIn) {
            if (this.props.location && this.props.location.state && this.props.location.state.referrer) {
                const referrer = this.props.location.state.referrer;
                return <Redirect to={`${referrer.pathname}${referrer.search}`}/>;
            } else {
                return <Redirect to="/"/>;
            }
        }

        return (
            <div className="container">
                <div className="banner">
                    <Card style={{ maxWidth: '19rem', marginBottom: '1.5rem' }}>
                        <H1>Sign in</H1>
                        <p className={Classes.TEXT_MUTED}>Welcome!<br />Please sign in to continue.</p>
                        <FormGroup
                            label="Username"
                            labelFor="username-input"
                        >
                            <InputGroup
                                id="username-input"
                                placeholder="Enter your username..."
                                large={true}
                                type="text"
                                onChange={event => this.setState({ username: event.target.value })}
                                onKeyPress={event => {
                                    if (event.key === 'Enter' && this.state.username !== '' && this.passwordInput) {
                                        this.passwordInput.focus();
                                    }
                                }}
                                autoFocus={true}
                            />
                        </FormGroup>

                        <FormGroup
                            label="Password"
                            labelFor="password-input"
                        >
                            <InputGroup
                                id="password-input"
                                placeholder="Enter your password..."
                                large={true}
                                rightElement={lockButton}
                                type={this.state.showPassword ? "text" : "password"}
                                onChange={event => this.setState({ password: event.target.value })}
                                onKeyPress={event => {
                                    if (event.key === 'Enter' && this.state.username !== '' && this.state.password !== '') {
                                        this.handleLoginButtonClicked();
                                    }
                                }}
                                inputRef={elem => {
                                    this.passwordInput = elem
                                }}
                            />
                        </FormGroup>

                        {this.state.error && <p style={{ color: Colors.RED1, textAlign: 'justify' }}>You have entered an invalid username or password.</p>}

                        <Button
                            intent={Intent.PRIMARY}
                            large={true}
                            fill={true}
                            onClick={() => {
                                if (this.state.username !== '' && this.state.password !== '') {
                                    this.handleLoginButtonClicked();
                                }
                            }}
                        >
                            Sign in
                        </Button>
                    </Card>
                </div>
            </div>
        );
    }
}))